import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Button from "../components/button";
import ContentBlock from "../components/content-block";
import CtaBlock from "../components/cta-block";

const Fees = ({ data }) => {
  const {
    description,
    banner,
    information,
    ctaBlock
  } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <Seo
        title={banner.title}
        description={description}
        imagePath={banner.bannerImage.publicURL}
      />
      <Hero
        fluid={banner.bannerImage.childImageSharp.fluid}
        title={banner.title}
        blurb={banner.blurb}
      />
      <Button url={banner.button.link} name={banner.button.name} standalone />
      <ContentBlock heading={information.heading} text={information.text} />
      <CtaBlock
        heading={ctaBlock.heading}
        ctaLink={ctaBlock.button.link}
        ctaName={ctaBlock.button.name}
      />
    </Layout>
  );
};

Fees.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }).isRequired
};

export default Fees;

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "fees" } }) {
      frontmatter {
        description
        banner {
          title
          bannerImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          blurb
          button {
            link
            name
          }
        }
        information {
          heading
          text
        }
        ctaBlock {
          heading
          button {
            name
            link
          }
        }
      }
    }
  }
`;
