import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Button from "./button";
import styles from "./cta-block.module.scss";

const CtaBlock = props => {
  const { fluid, heading, text, ctaLink, ctaName } = props;
  return (
    <section className={styles.ctaBlock}>
      {fluid && (
        <div className={styles.imageWrapper}>
          <Img className={styles.image} fluid={fluid} />
        </div>
      )}
      {heading && <h3>{heading}</h3>}
      {text && <p>{text}</p>}
      <Button className={styles.buttonWrapper} url={ctaLink} name={ctaName} />
    </section>
  );
};

CtaBlock.propTypes = {
  fluid: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  heading: PropTypes.string,
  text: PropTypes.string,
  ctaLink: PropTypes.string.isRequired,
  ctaName: PropTypes.string.isRequired
};

CtaBlock.defaultProps = {
  fluid: null,
  heading: null,
  text: null
};

export default CtaBlock;
