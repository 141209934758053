import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import styles from "./content-block.module.scss";

const ContentBlock = props => {
  const { heading, text } = props;

  return (
    <section className={styles.contentBlock}>
      <h3 className={styles.heading}>{heading}</h3>
      <div className={styles.text}>
        <ReactMarkdown source={text} />
      </div>
    </section>
  );
};

ContentBlock.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default ContentBlock;
