import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const Seo = ({ title, description, imagePath, data }) => {
  const { defaultTitle, defaultDescription } = data.site.siteMetadata;

  return (
    <Helmet>
      <title>{`${title} | ${defaultTitle}`}</title>
      <meta name="title" content={`${title} | ${defaultTitle}`} />
      <meta name="description" content={description || defaultDescription} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={
          typeof window !== "undefined" &&
          `${window.location.origin}${window.location.pathname}`
        }
      />
      <meta property="og:title" content={`${title} | ${defaultTitle}`} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta
        property="og:image"
        content={
          typeof window !== "undefined" &&
          `${window.location.origin}${imagePath}`
        }
      />
    </Helmet>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
          }
        }
      }
    `}
    render={data => <Seo data={data} {...props} />}
  />
);

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.object).isRequired
};
