import React from "react";
import PropTypes from "prop-types";
import Link from "./link";
import styles from "./button.module.scss";

const Button = props => {
  const { url, name, standalone, className } = props;
  return (
    <div
      className={`${className} ${styles.buttonWrapper} ${standalone &&
        styles.standalone}`}
    >
      <Link className={styles.button} to={url}>
        {name}
      </Link>
    </div>
  );
};

Button.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  standalone: PropTypes.bool,
  className: PropTypes.string
};

Button.defaultProps = {
  standalone: false,
  className: ""
};

export default Button;
