import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import styles from "./hero.module.scss";

const Hero = props => {
  const { fluid, title, subtitle, isHome, blurb } = props;
  return (
    <section className={`${styles.hero} ${isHome && styles.home}`}>
      <div className={styles.banner}>
        <Img className={styles.image} fluid={fluid} />
        <div className={styles.overlay} />
        <div className={styles.titles}>
          <h1>{title}</h1>
          {subtitle && <h5>{subtitle}</h5>}
        </div>
      </div>
      {!isHome && blurb && (
        <h3 className={styles.blurb}>
          <em>{blurb}</em>
        </h3>
      )}
    </section>
  );
};

Hero.propTypes = {
  fluid: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isHome: PropTypes.bool,
  blurb: PropTypes.string
};

Hero.defaultProps = {
  subtitle: null,
  isHome: false,
  blurb: null
};

export default Hero;
